import { Component } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../data.service';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  siteStatusData: string[] = ['On-going','Started','Completed','Stopped',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  designationForm: FormGroup = new FormGroup({});
  submitted: boolean = false;

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router
  ) {}

  ngOnInit()  {
    this.designationForm= this.formBuilder.group({
      designationName: ['', Validators.required],
     
      
    });
 
  }

 

  goBack() {
    this.router.navigate(['/dashboard/designation']);
  }
 
  get f() {
    return this.designationForm.controls;
  }

  onSubmit(data: any) {
    if (this.designationForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }
    data.postOn=moment().format()

    data.isActive = true 

    this.data.create(data).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/designation'])
    })
  }
  onCancel(){
    this.designationForm.reset()
  }
}
