import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  siteStatusData: string[] = ['On-going','Started','Completed','Stopped',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  designationForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  id:any

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {

    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.designationForm= this.formBuilder.group({
      designationName: ['', Validators.required],
     
      
    });
 
  }

 

  goBack() {
    this.router.navigate(['/dashboard/designation']);
  }
 
  get f() {
    return this.designationForm.controls;
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.designationForm.patchValue({
      designationName:data.designationName
    })
  }

  onSubmit(data: any) {
    if (this.designationForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }
    data.updateOn=moment().format()

    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/designation'])
    })
  }
  onCancel(){
    this.designationForm.reset()
  }
}
