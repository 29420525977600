
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New Designation</h2>
    <form [formGroup]="designationForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="designationName">Designation Name<span class="requried-field">*</span></label>
          <input
            type="text"
            id="designationName"
            placeholder="Enter designationName"
            class="form-control"
            formControlName="designationName"
            [ngClass]="{ 'is-invalid': submitted && f['designationName'].errors }"
          />
          <div *ngIf="submitted && f['designationName'].errors" class="invalid-feedback">
            <div *ngIf="f['designationName'].errors['required']">Designation is required</div>
          </div>
        </div>
        </div>
       

      
  
      <div class="formbutton">
        <button type="submit" class="btn btn-primary" (click)="onSubmit(designationForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  